@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'studiofeixen';
  src: url('https://fonts.withoutdoing.com/StudioFeixenSansEdgy-Bold.eot');
  src: url('https://font.withoutdoing.com/StudioFeixenSansEdgy-Bold.eot')
      format('embedded-opentype'),
    url('https://fonts.withoutdoing.com/StudioFeixenSansEdgy-Bold.woff2')
      format('woff2'),
    url('https://fonts.withoutdoing.com/StudioFeixenSansEdgy-Bold')
      format('woff'),
    url('https://fonts.withoutdoing.com/StudioFeixenSansEdgy-Bold.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  --reach-tooltip: 1;
}

/* node_modules/@reach/tooltip/styles.css */
[data-reach-tooltip] {
  pointer-events: none;
  position: absolute;
  white-space: nowrap;
  @apply animate-slide-up-fade bg-white border border-neutral-700 px-2 py-1 rounded shadow shadow-neutral-900 text-xs z-50;
}

html {
  --logo-fill: #06090b;
  color-scheme: light;
}

html[data-theme='dark'] {
  --logo-fill: #fff;
  color-scheme: dark;
}

ol,
ul,
menu {
  list-style: inherit;
}

.CodeMirror {
  height: auto !important;
  border-radius: 6px;
}

/* Don't show default up / down arrows on number input. */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@layer base {
  :root {
    --black: 17, 18, 19;
    --dark: 25, 29, 31;
    --light: 243, 246, 248;
    --white: 255, 255, 255;
    --error: 199, 62, 89;
    --valid: 57, 166, 153;
    --brand: 77, 76, 185;
    --active: 87, 86, 213;
    /* Decreased L in HSL of .dark's --accent from 63% to 53% to improve
     * contrast on light mode.
     */
    --accent: 253, 18, 69;
    --very-light: 255, 255, 255;
  }

  .dark {
    --white: 17, 18, 19;
    --light: 25, 29, 31;
    --dark: 243, 246, 248;
    --black: 255, 255, 255;
    --error: 199, 62, 89;
    --valid: 57, 166, 153;
    --brand: 95, 94, 255;
    --active: 77, 76, 185;
    --accent: 253, 67, 107;
    --very-light: 9, 13, 16;
  }

  .hero-text {
    font-family: 'studiofeixen';
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 1;
    color: rgba(var(--dark, 1));
  }

  .header-text {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: rgba(var(--dark), 1);
  }

  .title-text {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: rgba(var(--dark), 1);
  }

  .primary-text {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: rgba(var(--dark), 0.95);
  }

  .body-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(var(--dark), 0.95);
  }

  .button-text {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: rgba(var(--white), 1);
  }

  .link-text {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: rgba(var(--dark), 0.95);
  }

  .secondary-text {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: rgba(var(--dark), 0.75);
  }

  .caption-text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: rgba(var(--dark), 0.6);
  }

  .gradient-text {
    background: radial-gradient(
      146.53% 281.23% at 4.24% 23.36%,
      #f99974 0%,
      #fe4366 16.67%,
      #f43d88 40.23%,
      #d72de5 55.27%,
      #3b7bea 68.75%,
      #30b1cd 82.81%,
      #40cad7 92.71%,
      #09aca2 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    mix-blend-mode: normal;
  }
}
